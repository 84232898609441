import React from "react";
import useStore from "../store";

function ProductCard({
  imagen,
  nombre,
  descripcion,
  precio,
  tallas,
  colores,
  id,
  price_id,
  setCartOpen,
}) {
  const [talla, setTalla] = React.useState("");
  const [color, setColor] = React.useState("");
  const addToCart = useStore((state) => state.addToCart);

  const isBuyButtonDisabled =
    (tallas && !talla) || (colores.length > 0 && !color);

  return (
    <div>
      <div className="flex flex-col justify-center rounded-3xl w-72 shadow-xl shadow-black overflow-hidden">
        <img src={imagen} alt={nombre} className="p-10 object-cover" />

        <div className=" bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-700 via-gray-900 to-black flex flex-col justify-center items-center p-10 gap-4 w-full">
          <h1 className="text-red-600 font-semibold text-center text-2xl">
            {nombre}
          </h1>
          <p className="text-white text-center font-light text-base">
            {descripcion}
          </p>

          {(tallas || colores.length > 0) && (
            <div className="w-full h-1 bg-white my-4"></div>
          )}

          {tallas && (
            <>
              <select
                name="size"
                id="s"
                className="rounded-xl text-center w-52 h-10 mb-6"
                value={talla}
                onChange={(e) => setTalla(e.target.value)}
              >
                <option value="" disabled hidden>
                  Estatura del alumno
                </option>
                <option value="90">90 cms</option>
                <option value="95">95 cms</option>
                <option value="100">100 cms</option>
                <option value="105">105 cms</option>
                <option value="110">110 cms</option>
                <option value="115">115 cms</option>
                <option value="120">120 cms</option>
                <option value="125">125 cms</option>
                <option value="130">130 cms</option>
                <option value="135">135 cms</option>
                <option value="140">140 cms</option>
                <option value="145">145 cms</option>
                <option value="150">150 cms</option>
                <option value="155">155 cms</option>
                <option value="160">160 cms</option>
                <option value="165">165 cms</option>
                <option value="170">170 cms</option>
                <option value="175">175 cms</option>
                <option value="180">180 cms</option>
                <option value="185">185 cms</option>
                <option value="190">190 cms</option>
              </select>
            </>
          )}

          {colores.length > 0 && (
            <>
              <select
                name="color"
                id="c"
                className="rounded-xl text-center w-52 h-10 mb-6"
                value={color}
                onChange={(e) => setColor(e.target.value)}
              >
                <option value="" disabled hidden>
                  Color
                </option>
                {colores.map((color) => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
              </select>
            </>
          )}

          <p className="text-white text-xl font-semibold">
            $ {nombre === "Bo" && talla > 120 ? (precio + 115).toLocaleString() : precio.toLocaleString()}
          </p>
          {isBuyButtonDisabled ? (
            <button
              className="text-sm font-normal text-white bg-gray-700 py-2 px-10 rounded-3xl shadow-lg shadow-black"
              onClick={() => alert("Selecciona una opción")}
            >
              Comprar
            </button>
          ) : (
            <button
              className="text-sm font-normal text-white bg-red-700 py-2 px-10 rounded-3xl shadow-lg shadow-black"
              onClick={() => {
                addToCart({
                  imagen,
                  nombre,
                  precio: nombre === "Bo" && talla > 120 ? precio + 115 : precio,
                  talla,
                  color,
                  id,
                  price_id:nombre === "Bo" && talla > 120 ?price_id[1]:price_id[0],
                });
                setCartOpen(true);
              }}
            >
              Comprar
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
