import React, { useState, useEffect } from "react";
import ProductCard from "../components/ProductCard";
import { supabase } from "../supabase";
import useStore from "../store";
import { AiFillShopping, AiFillCloseCircle } from "react-icons/ai";
import Cart from "../components/Cart";
import {isMobile} from 'react-device-detect';


export default function Home() {
  const [productos, setProductos] = useState([]);
  const [cartOpen, setCartOpen] = useState(false);
  const cart = useStore((state) => state.cart);

  useEffect(() => {
    function getData() {
      supabase
        .from("Productos")
        .select("*")
        .eq("active", true)
        .then(({ data, error }) => {
          if (error) console.log(error);
          else setProductos(data);
        });
    }
    getData();
  }, []);

  console.log(cart);

  return (
    <div
      className="w-full h-screen bg-fixed bg-cover bg-center bg-no-repeat overflow-auto font-poppins flex flex-col items-center"
      style={{
        backgroundImage: isMobile?`url("https://res.cloudinary.com/dt9ivv2ug/image/upload/v1694751079/tienda2_mveygx.png")`:`url("https://res.cloudinary.com/dt9ivv2ug/image/upload/v1694891351/tiendah_p6yetv.jpg")`,
      }}
    >
      <h1 className="text-4xl w-[90%] py-10 text-center text-white rounded-t-3xl mt-[70vh] bg-red-600">Tienda Kumo</h1>
      <div className="pt-10 w-[90%] bg-white rounded-b-3xl shadow-2xl shadow-black flex flex-wrap justify-center items-center gap-16 pb-40 self-center mb-20 md:p-20">
        {productos.map((producto) => (
          <ProductCard
            key={producto.id}
            nombre={producto.nombre}
            imagen={producto.imagen}
            descripcion={producto.descripcion}
            precio={producto.precio}
            tallas={producto.tallas}
            colores={producto.colores}
            id={producto.id}
            price_id={producto.price_id}
            setCartOpen={setCartOpen}
          />
        ))}
      </div>
      {cartOpen && cart.length > 0 && <Cart setCartOpen={setCartOpen} />}

      {cart.length >= 1 && (
        <div
          className="fixed h-10 w-10 bg-red-600 rounded-full border border-white top-4 right-4 md:right-20 flex justify-center items-center"
          onClick={() => {
            setCartOpen(!cartOpen);
          }}
        >
          {cartOpen ? (
            <AiFillCloseCircle className="text-white text-2xl m-auto" />
          ) : (
            <AiFillShopping className="text-white text-2xl m-auto" />
          )}
        </div>
      )}
    </div>
  );
}
