import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useStore = create(persist(
    (set) => ({
        
            user: null,
            setUser: (user) => set((state) => ({ user })),
            cart: [],
            setCart: (cart) => set((state) => ({ cart })),
            addToCart: (product) =>
                set((state) => ({
                    cart: [...state.cart, product],
                })),
            removeFromCart: (product) =>
                set((state) => ({
                    cart: state.cart.filter((p) => p.id !== product.id),
                })),
        }),
        {
            name: "shopping-cart",
            getStorage: () => createJSONStorage(() => sessionStorage),
        }
    )
);

export default useStore;